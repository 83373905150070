<template>
  <div class="satisfiedInvestigate">
    <img src="./img/changting.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "satisfiedInvestigate",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.satisfiedInvestigate {
  height: 100vh;
  background: #eaf4fe;
  img {
    width: 100%;
  }
}
</style>
